import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      home: 'Home',
      wallet: 'Wallet',
      last2digit: 'LAST 2 DIGIT',
      Deposit: 'Deposit',
      withdrawal: 'withdrawal',
      stakinghistory: 'Staking History',
      last2dhistory: 'L2D History',
      profile: 'Profile',
      myreferrals: 'My Referrals',
      help: 'Help',
      signout: 'Sign Out',
      stake: 'Stake',
      match: 'Match',
      yourbet: 'Your bet',
      reward: 'Reward',
      newton: 'Last 2 Digit of Newton Force',
      jackpot: 'Jackpot Prize',
      winningresult: 'Winning Results',
      todaymatch: 'Today`s Match',
      noupcoming: 'no upcoming fights found',
      quickstake: 'Quick Stake',
      stakeamount: 'Stake Amount',
      entermatchnumber: 'Enter a match number',
      matchhistory: 'Match History',
      matchno: 'Match No',
      winner: 'Winner',
      date: 'Date',
      nohistoryfound: 'No history found',
      content: 'Connect to Ethereum or Arbitrum One or polygon network in your metamask wallet',
      withdraw: 'Withdraw',
      transactionHis: 'Transaction History - USD',
      transactionnotavailable: 'Transaction not available',
      dateandtime: 'Date and Time',
      description: 'Description',
      amountin: 'Amount In',
      amountout: 'Amount Out',
      details: 'Details',
      noitem: 'no items',
      depositcontent: 'Deposit your Game Wallet here.',
      cryptowallet: 'Crypto Wallet Balance',
      amount: 'Amount',
      view: 'View on Explorer',
      forsolana: 'For Solana Users',
      scan: 'Scan or copy the deposit address.',
      network: 'Send',
      send: 'in the solana network.',
      share: 'Share the transaction reference here.',
      txhash: 'Enter TX hash',
      submit: 'Submit',
      footer: 'All right reserved by Cockboxing Live',
      withdrawcontent: 'Minimum withdrawal of $50, Gas fee will be deducted from the withdrawal.',
      gamewalletbalance: 'Game Wallet Balance',
      enterwalletaddress: 'Enter Wallet Address',
      enteramount: 'Enter Amount',
      chooseyourchainbelow: 'Choose your chain below',
      withdrawhistory: 'Withdraw History',
      withdrawnotavailable: 'withdraw not available',
      walletaddress: 'Wallet Address',
      status: 'Status',
      mystaking: 'My Staking',
      transaction: 'Transaction not available',
      mylast2dhistory: 'My Last 2 Digit History',
      historynotavailable: ' Last 2 Digit history not available',
      combination: 'Combination',
      myprofile: 'My Profile',
      firstname: 'FirstName',
      lastname: 'LastName',
      email: 'Email',
      id: 'Id',
      country: 'Country',
      referrallink: 'Referral Link',
      update: 'Update',
      selectcountry: 'Select Country',
      myreferrallist: 'My Referral List',
      startdate: 'Start Date',
      enddate: 'End Date',
      usersnotavailable: 'Users not available',
      name: 'Name',
      stakedamount: 'Staked Amount',
      commission: 'Commission',
      cockboxing: 'CockBoxing',
      cockboxingpara1:
        'CockBoxing is a ground-breaking innovation. An accessible, new, non-lethal sport based on boxing, fencing, cockfighting and other sports that can be participated worldwide.',
      cockboxingpara2:
        'It is a bloodless alternative to traditional cockfighting that is the first of its kind.',
      cockboxingpara3:
        'It introduces new innovation like a digital glove, and a new digital scoring system that protects the combatants from any harm.',
      digital: 'Digital Scoring System',
      digitalpara:
        'The advance Digital Scoring System will provide users the best and most fair chance in their gaming experience with no human error and no human intervention in every single match result.',
      staking: 'Staking',
      stakingpara:
        'This betting system will let customers choose between two (2) sides (White or Red) to participate in the game.',
      last2digitbetting: 'Last 2 Digit Betting',
      last2digitpara:
        'The Last 2 Digit betting is a lottery style betting system that determines the winning numbers from the last 2 digits of a Cockboxing match.',
      cockboxingrules: 'Cockboxing Implementing Rules',
      winnerandduration: 'Winner and Duration',
      winnerpara: 'Each match will be conducted with a force limit of 5 Newtons.',
      winnerpara1:
        'The first combatant to register a cumulative 5 Newton Force hit(s) to its opponentwins the match.',
      winnerpara2: 'The duration of each match will be set at 1 minute.',
      referauthority: 'Referee Authority',
      authoritypara: 'The referees decision is final and binding.',
      authoritpara1:
        'Only the referee has the authority to operate the digital gloves and Blackbox (controls the digital scoring system) during the match.',
      fighters: 'Fighters Eligibility',
      fighterspara:
        'Only two healthy and ready fighters will be allowed to participate in each match.',
      tecnicalissue: 'Technical Issues and Cancellations',
      tecnicalissuepara:
        'Fights will be canceled in the case of malfunctioning or broken gloves, technical issues, or if a game fowl is unable to participate.',
      unusual: 'Unusual Wins and Rematch',
      unusualpara:
        'Unusual wins, such as a fighter hitting its own leg (cross legs) or no Newtons Force scored after 1 minute, will result in a declared rematch.',
      implementingrules: 'Last 2 Digit Implementing Rules',
      rules1:
        'The order of the winning combination will always be the last 2 digits from the White corner to the last 2 digits from the Red Corner.',
      rules2:
        'The winning combination will only be declared after a winning game fowl is determined.',
      rules3:
        ' The computation of the total JACKPOT for the current match will be the accumulated stake for the match plus the JACKPOT from the previous match if there is no winning combination.',
      rules4:
        'If there’s a winning combination, the computation of the price will be based on the formula:',
      rules5: 'Total JACKPOT / Total No. of Points Placed on the Winning Combination',
      feedback:
        'We value your feedback and are here to assist you with any questions or concerns you may have. Our dedicated support team is committed to providing you with the best possible service.',
      foradditional: 'For additional support or inquiries, please reach out to us at:',
      signin: 'Sign In',
      password: 'Password',
      donthaveaccount: 'Don’t have an account',
      dontrememberpassword: 'Don’t you remember Password',
      signup: 'Sign Up',
      forgotpassword: 'Forgot Password',
      createaccount: 'Create Account',
      alreadyhaveanaccount: 'Already have an account',
      login: 'Login',
      pleasewait: 'Please Wait....',
      confirmpassword: 'Confirm Password',
      referid: 'Refer Id',
      operatorid: 'Operator Id',
      ivalidoperatorid: 'Invalid Operator ID',
      invalidreferid: 'Invalid Refer Id',
      trying: 'Trying to retrieve referral information and operator',
      sendemail: 'Send Email',
      goto: 'Go to',
      placecombination: 'Place Combination',
      totalcombination: 'Total Combination',
      matchnumber: 'Match Number',
      totalamount: 'Total Amount ',
      confirm: 'CONFIRM',
      manual: 'Manual',
      auto: 'Auto',
      balance: 'Balance',
      typeamount: 'Type Amount',
      StakeAmountin: 'Stake Amount in',
    },
  },
  ko: {
    translation: {
      home: '홈',
      wallet: '지갑',
      last2digit: '마지막 2자리',
      withdrawal: '출금',
      stakinghistory: '배팅 기록',
      last2dhistory: 'L2D 기록',
      profile: '프로필 ',
      myreferrals: '내 추천목록',
      help: '지원',
      signout: '로그아웃',
      match: '매칭',
      stake: '배팅',
      yourbet: '당신의 배팅',
      reward: '보상',
      newton: '뉴턴포스의 마지막 두자리',
      jackpot: '잭팟 상금',
      winningresult: '승리결과',
      todaymatch: '오늘의 매칭',
      noupcoming: '예정된 경기가 없습니다',
      quickstake: '빠른 배팅',
      stakeamount: '배팅 금액 ',
      entermatchnumber: '일치번호를 입력하세요.',
      matchhistory: '매칭 기록',
      matchno: '매칭 번호 ',
      winner: '승리자',
      date: '날짜',
      nohistoryfound: '기록을 찾을 수 없습니다',
      content: 'Metamask 지갑에서 Ethereum 또는 Arbitrum One 또는 Polygon 네트워크에 연결하세요',
      Deposit: '입금',
      withdraw: '출금',
      transactionHis: '거래내역 - USD',
      transactionnotavailable: '거래불가',
      dateandtime: '날짜와 시간',
      description: '상세정보',
      amountin: '입금 금액',
      amountout: '출금 금액',
      details: '상세정보',
      noitem: '항목 없음',
      depositcontent: '여기에 게임지갑을 입금하세요.',
      cryptowallet: '암호화폐 지갑 잔액',
      amount: '금액',
      view: '탐색기에서 보기',
      forsolana: '솔라나 사용자용',
      scan: '입금주소를 스캔 또는 복사하세요.',
      network: '솔라나 네트워크에서 ',
      send: '를 보내세요',
      share: '거래참조를 여기에서 공유하세요.',
      txhash: 'TX 해시를 입력하세요.',
      submit: '제출',
      footer: '모든 권리는 Cockboxing에 있습니다.',
      withdrawcontent: '게임지갑 잔액에서 출금하세요.',
      gamewalletbalance: '게임 지갑 잔액',
      enterwalletaddress: '지갑주소를 입력하세요.',
      enteramount: '금액을 입력하세요.',
      chooseyourchainbelow: '아래에서 체인을 선택하세요.',
      withdrawhistory: '출금내역',
      withdrawnotavailable: '출금이 불가합니다.',
      walletaddress: '지갑주소',
      status: '상태',
      mystaking: '내 배팅기록',
      transaction: '거래를 사용할수없습니다.',
      mylast2dhistory: '내 마지막 2자리 기록내역',
      historynotavailable: '마지막 2자리 기록이 없습니다.',
      combination: '조합',
      myprofile: '내 프로필',
      firstname: '성',
      lastname: '이름',
      email: '이메일',
      id: 'ID',
      country: '국가',
      referrallink: '추천 링크',
      update: '업데이트',
      selectcountry: '국가 선택',
      myreferrallist: '나의 추천목록',
      startdate: '시작날짜',
      enddate: '종료날짜',
      usersnotavailable: '사용자가 없습니다.',
      name: '이름',
      stakedamount: '배팅 금액',
      commission: '수수료',
      cockboxing: '닭싸움',
      cockboxingpara1:
        '닭싸움은 획기적인 혁신입니다. 복싱, 펜싱, 닭싸움 및 기타 스포츠를 기반으로 한 새롭고 치명적이지 않은 스포츠로 전 세계적에서 참여할 수 있습니다.',
      cockboxingpara2: '전통적인 닭 싸움의 무혈 대안으로 최초의 닭싸움입니다.',
      cockboxingpara3: '디지털 장갑과 새로운 디지털 점수 시스템을 도입하여 참가자들을 보호합니다.',
      digital: '디지털 점수 시스템',
      digitalpara:
        '첨단 디지털 점수 시스템은 모든 경기 결과에 사람의 실수나 개입 없이 공정한 게임 기회를 제공합니다.',
      staking: '배팅',
      stakingpara:
        '이 배팅 시스템은 고객이 두개의 측면(화이트 또는 레드) 중에서 하나를 선택할 수 있습니다.',
      last2digitbetting: '마지막 2자리 배팅',
      last2digitpara:
        '마지막 2자리 배팅은 닭싸움 경기의 마지막 2자리 숫자를 기반으로 승리 숫자를 결정하는 복권 스타일의 배팅 시스템입니다.',
      cockboxingrules: '닭싸움 적용 규칙',
      winnerandduration: '우승자 및 시간',
      winnerpara: '경기는 최대 5 뉴턴의 힘 제한으로 진행됩니다.',
      winnerpara1: '상대에게 누적 5개의 뉴턴 포스 히트를 기록한 첫번째 선수가 승리합니다.',
      winnerpara2: '각 경기시간은 1분으로 설정됩니다.',
      referauthority: '심판권한',
      authoritypara: '심판 판정은 최종적이고 구속력이 있습니다.',
      authoritpara1:
        '심판만이 경기 중 디지털 글러브와 블랙박스(디지털 채점 시스템 제어)를 조작할 수 있는 권한을 갖습니다.',
      fighters: '전투기 자격',
      fighterspara: '각 경기에 참가할 수 있는 건강하고 준비된 선수는 두 명만 허용됩니다.',
      tecnicalissue: '기술적 문제 및 취소',
      tecnicalissuepara:
        '장갑이 오작동하거나 파손되었거나 기술적 문제가 있거나 게임이 가능한 닭이 참여할 수 없는 경우 경기는 취소됩니다.',
      unusual: '비정상적인 승리 및 재대결',
      unusualpara:
        '예를 들어, 전투자가 자신의 다리(다리 교차)를 치거나 1분이 지나도 뉴턴 포스가 기록되지 않는 등의 비정상적인 승리는 재경기를 선언합니다.',
      implementingrules: '마지막 2자리 적용 규칙',
      rules1:
        '승리 조합의 순서는 항상 화이트 코너의 마지막 2자리 숫자에서 레드 코너의 마지막 2자리 숫자로 진행됩니다.',
      rules2: '승리하는 게임 닭이 결정된 후에만 승리 조합이 선언됩니다.',
      rules3:
        '현재 경기의 총 잭팟 계산은 경기의 누적 지분과 승리 조합이 없는 경우 이전 경기의 잭팟을 더한 값이 됩니다.',
      rules4: '승리하는 조합이 있는 경우 가격 계산은 다음 공식을 기반으로 합니다',
      rules5:
        '승리조합에 배치된 총잭팟을 총 포인트수로 나누는 공식에 기반합니다.(승리 조합에 배치된 총 잭팟/총 포인트 수)',
      feedback:
        '우리는 여러분의 피드백을 소중히 여기며, 질문이나 우려 사항이 있으면 도와드리겠습니다. 우리의 헌신적인 지원 팀이 최상의 서비스를 제공하기 위해 노력하고 있습니다.',
      foradditional: '추가 지원이나 문의 사항이 있으면 다음으로 연락 주시기 바랍니다.',
      signin: '로그인',
      password: '비밀번호',
      donthaveaccount: '계정이 없습니다.',
      dontrememberpassword: '비밀번호가 기억나지않으시나요?',
      signup: '회원가입',
      forgotpassword: '비밀번호를 잊어버렸습니다.',
      createaccount: '새 계정 만들기',
      alreadyhaveanaccount: '이미 계정이 있습니다.',
      login: '로그인',
      pleasewait: '기다려주세요....',
      confirmpassword: '비밀번호 확인',
      referid: '참조 ID',
      operatorid: '운영자 ID',
      ivalidoperatorid: '잘못된 운영자 ID',
      invalidreferid: '유효하지 않는 참조 ID',
      trying: '추천 정보와 운영자를 검색하는 중입니다.',
      sendemail: '이메일을 보내다',
      goto: '가다',
      placecombination: '조합 배팅',
      totalcombination: '전체 조합',
      matchnumber: '매칭 번호',
      totalamount: '합계 금액',
      confirm: '확인하다',
      manual: '메뉴얼',
      auto: '자동',
      balance: '균형',
      typeamount: '종류 금액',
      StakeAmountin: '지분 금액',
    },
  },
  ja: {
    translation: {
      home: 'ホーム',
      wallet: 'ウォレット',
      last2digit: '下2桁',
      Deposit: '入金',
      withdrawal: '出金',
      stakinghistory: 'ステーキング履歴',
      last2dhistory: 'L2D履歴',
      profile: 'プロフィール',
      myreferrals: '自分の情報',
      help: 'ヘルプ',
      signout: 'サインアウト',
      match: '試合',
      stake: '掛け金',
      yourbet: 'あなたの掛け金',
      reward: '報酬',
      newton: 'ニュートンフォースの下2桁',
      jackpot: 'ジャックポットの賞金',
      winningresult: '勝利者結果',
      todaymatch: '本日の試合',
      noupcoming: '今後の試合は見つかりませんでした',
      quickstake: 'クイックステーキング',
      stakeamount: '掛けた金額',
      entermatchnumber: '試合番号を入力',
      matchhistory: '試合履歴',
      matchno: '試合番号',
      winner: '勝者',
      date: '日付',
      nohistoryfound: '履歴が見つかりません',
      content:
        'メタマスクウォレットでイーサリアムまたはアービトラムワンまたはポリゴンネットワークに接続する',
      withdraw: '引き出し履歴',
      transactionHis: '取引履歴 - USD',
      transactionnotavailable: '取引不可',
      dateandtime: '日付と時間',
      description: '記載',
      amountin: '入金額',
      amountout: '出金額',
      details: '詳細',
      depositcontent: 'ゲームウォレットをここに入金する',
      cryptowallet: 'クリプトウォレット残高',
      amount: '金額',
      view: 'エクスプローラーで見る',
      forsolana: 'ソラナユーザーの場合',
      scan: '入金先のアドレスをスキャンもしくはコピーしてください。',
      network: 'solana ネットワークで ',
      send: ' を送金します。',
      share: '取引参照をここで共有する.',
      txhash: 'TXハッシュを入力',
      submit: '送信',
      footer: 'すべての著作権はコックボクシングライブに帰属します。',
      withdrawcontent: 'ゲームウォレットの残高から引き出す。',
      gamewalletbalance: 'ゲームウォレット残高',
      enterwalletaddress: 'ウォレットアドレスを入力',
      enteramount: '金額を入力',
      chooseyourchainbelow: '以下からチェーンを選択してください。',
      withdrawhistory: '引き出し履歴',
      withdrawnotavailable: '出金不可',
      walletaddress: 'ウォレットアドレス',
      status: 'ステータス',
      mystaking: '私のステーキング',
      transaction: '取引は利用できません',
      mylast2dhistory: '直近2桁の履歴',
      historynotavailable: '最後の2桁の履歴はありません',
      combination: '組み合わせ',
      myprofile: 'プロフィール',
      firstname: '姓（せい）',
      lastname: '名（めい）',
      email: 'Eメール',
      id: '名前',
      country: '国名',
      referrallink: '紹介リンク',
      update: '更新',
      noitem: 'アイテムなし',
      selectcountry: '国を選択してください',
      myreferrallist: '紹介者リスト',
      startdate: '開始日',
      enddate: '終了日',
      usersnotavailable: '利用できないユーザー',
      name: '名前',
      stakedamount: '賭け金額',
      commission: '手数料',
      cockboxing: 'コックボクシング',
      cockboxingpara1:
        'コックボクシングは、ボクシングやフェンシング、闘鶏、その他のスポーツをベースとしており、世界中どこからでも参加できる今までにない革新的なスポーツです。',
      cockboxingpara2:
        '通常、闘鶏では出血を伴いますが、コックボクシングでは出血がなく行われ、この種のスポーツとしては初めての試みです。',
      cockboxingpara3:
        'デジタル・グローブや新しいデジタル採点システムを導入しており、闘鶏士をあらゆる危害から守っている。',
      digital: 'デジタル採点システム',
      digitalpara:
        'デジタル採点システムを用いることにより、人為的なミスや不正を排除し、公平なゲーム体験を提供します。',
      staking: 'ステーキング',
      stakingpara:
        'このベッティングシステムは、お客様に2つのサイド（白または赤）を選んでゲームに参加していただきます。',
      last2digitbetting: '下2桁ベッティング',
      last2digitpara:
        '下2桁ベッティングは、コックボクシングの試合番号から下2桁の当選番号を決定し投票する抽選スタイルのベッティングシステムです。',
      cockboxingrules: 'コックボクシングのルール',
      winnerandduration: '勝敗と試合時間',
      winnerpara: '各試合は最大5ニュートンフォースで行われる。',
      winnerpara1: '対戦相手に5ニュートンのフォース・ヒットを与えた方が勝ちとなる。',
      winnerpara2: '試合時間は1分とする。',
      referauthority: 'レフェリーの権限',
      authoritypara: 'レフェリーの判断は最終的なものであり、拘束力を持つ。',
      authoritpara1:
        '試合中、デジタルグローブとブラックボックス（デジタル採点システム）を操作する権限を持つのはレフェリーのみである。',
      fighters: '出場資格',
      fighterspara: '各試合に出場できるのは、健康で闘う準備のできたファイター(闘鶏)2匹のみとする。',
      tecnicalissue: '技術的問題と中止',
      tecnicalissuepara:
        'グローブの故障や破損、技術的な問題、試合出場が不可能な場合、試合は中止となる。',
      unusual: '特殊な勝利と再戦',
      unusualpara:
        '自分の足を打った場合（クロスレッグ）、1分経過してもニュートン・フォースが入らな✎った場合など、異常な勝➀は再戦が宣告される。',
      implementingrules: '下2桁に関するルール',
      rules1: '下2桁の番号を選ぶ際は、必ず白コーナーの下2桁✎ら赤コーナーの下2桁の順番となる。',
      rules2:
        'ウイニングコンビネーションは、ウイニングゲームファウルが決定された後にのみ宣言されます。',
      rules3:
        'その試合の合計JACKPOTの計算は、勝➀の組み合わせがない場合、その試合の累積賭け金と、前の試合のJACKPOTとなります。',
      rules4: '勝➀の組み合わせがある場合、価格の計算は以下の式に基づきます。',
      rules5: '合計JACKPOT / 勝利の組み合わせに置✎れたポイントの合計数',
      feedback:
        '私どもはお客様のご意見を大切にしておりますので、ご質問やご不明な点がございましたら、お気軽にお問い合わせください。私どものサポートチームは、お客様に最高のサービスを提供することをお約束いたします。',
      foradditional: 'その他のサポートやお問い合わせは、下記までご連絡ください。',
      signin: 'サインイン',
      password: 'パスワード',
      donthaveaccount: 'アカウントをお持ちでない方',
      dontrememberpassword: 'パスワードを忘れた方',
      signup: '新規登録',
      forgotpassword: 'パスワードを忘れた方',
      createaccount: '新規アカウント作成',
      alreadyhaveanaccount: 'すでにアカウントをお持ちの方',
      login: 'ログイン',
      pleasewait: '少々お待ちください....',
      confirmpassword: '確認パスワード',
      referid: '参照ID',
      operatorid: 'オペレーター ID',
      ivalidoperatorid: '無効なオペレーターID',
      invalidreferid: '無効な参照ID',
      trying: '参照およびオペレーター情報の取得',
      sendemail: '電子メールを送信する',
      goto: 'に行く',
      placecombination: '数字の組み合わせ',
      totalcombination: 'すべての組み合わせ',
      matchnumber: '試合番号',
      totalamount: '合計金額',
      confirm: '確認',
      manual: 'マニュアル',
      auto: '自動',
      balance: 'バランス',
      typeamount: 'タイプ金額',
      StakeAmountin: 'の賭け金額',
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
