import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import classes from './Layout.module.sass';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Divider, ListItemAvatar, ListItemIcon, Theme } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Walleticon from '../../src/assets/Images/wallet.png';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchangeRounded';
import { useAuth } from '../context/AuthContext';
import logo from '../assets/Images/logohorizontal.png';
import MoneyIcon from '@mui/icons-material/Money';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { TbUsersPlus } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';

const DrawerContent = () => {
  const navigate = useNavigate();
  const { logOut } = useAuth();
  const { t } = useTranslation();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const GoToHome = () => {
    navigate('/');
  };

  // const GoToMarketplace = () => {
  //   navigate('/Marketplace');
  // };

  const GoToWallet = () => {
    navigate('/Wallet');
  };

  const GoToTopUp = () => {
    navigate('/TopUp');
  };
  const GoToWithdraw = () => {
    navigate('/Withdraw');
  };
  // const GoToReward = () => {
  //   navigate('/Reward');
  // };
  const GoToAccounts = () => {
    navigate('/accounts');
  };
  const GoToHelp = () => {
    navigate('/Help');
  };
  const GoToBetsTable = () => {
    navigate('/Bets');
  };

  const GoToCockPickHistory = () => {
    navigate('/CockpickHistory');
  };

  const GoToCockPickPage = () => {
    navigate('/Cockpick');
  };
  // const GoToReferal = () => {
  //   navigate('/MyReferal');
  // };
  return (
    <div>
      <Box className={classes.drawer}>
        <List className={classes.navList}>
          {/* {isMobile ? ( */}
          <>
            <img
              src={logo}
              className={classes.navlogoimage}
              width='160px'
              onClick={() => {
                navigate('/Home');
              }}
            />
            <Divider />
            {/* <ListItem button onClick={() => GoToHome()}>
                <ListItemIcon>
                  <HomeOutlinedIcon sx={{ color: 'white', fontSize: '30px' }} />
                </ListItemIcon>
                <ListItemText primary='HOME' className={classes.listText} />
              </ListItem>

              <ListItem button onClick={() => GoToMarketplace()}>
                <ListItemIcon>
                  <StorefrontOutlinedIcon sx={{ color: 'white', fontSize: '30px' }} />
                </ListItemIcon>
                <ListItemText primary='MARKETPLACE' className={classes.listText} />
              </ListItem> */}
          </>
          {/* ) : null} */}
          {/* <ListItem button sx={{ marginTop: { lg: '80px', xs: '0px' } }}>
            <Box
              component='img'
              sx={{
                height: '30px',
                width: '30px',
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt='The house from the offer.'
              src={Bellicon}
            />
            <ListItemText primary='NOTIFICATION' className={classes.listText} />
          </ListItem> */}
          <ListItem button onClick={() => GoToHome()} sx={{ marginTop: { xs: '0px' } }}>
            <ListItemIcon>
              <HomeOutlinedIcon sx={{ color: 'white', fontSize: '30px' }} />
            </ListItemIcon>
            <ListItemText primary={t('home')} className={classes.listText} />
          </ListItem>

          {isMobile ? (
            <ListItem button onClick={() => GoToCockPickPage()}>
              <ListItemIcon>
                <MoneyIcon sx={{ color: 'white', fontSize: '30px' }} />
              </ListItemIcon>
              <ListItemText primary={t('last2digit')} className={classes.listText} />
            </ListItem>
          ) : null}

          <ListItem button onClick={() => GoToWallet()}>
            <ListItemAvatar>
              <Box
                component='img'
                sx={{
                  height: '26px',
                  width: '26px',
                }}
                alt='wallet icon'
                src={Walleticon}
              />
            </ListItemAvatar>
            <ListItemText primary={t('wallet')} className={classes.listText} />
          </ListItem>

          <ListItem button onClick={() => GoToTopUp()}>
            <ListItemIcon>
              <CurrencyExchangeIcon sx={{ color: 'white', fontSize: '30px' }} />
            </ListItemIcon>
            <ListItemText primary={t('Deposit')} className={classes.listText} />
          </ListItem>
          <ListItem button onClick={() => GoToWithdraw()}>
            <ListItemIcon>
              <CurrencyExchangeIcon sx={{ color: 'white', fontSize: '30px' }} />
            </ListItemIcon>
            <ListItemText primary={t('withdrawal')} className={classes.listText} />
          </ListItem>
          {/* 
          <ListItem  button onClick={() => GoToReward()}>
            <ListItemIcon>
              <EmojiEventsIcon sx={{ color: 'white', fontSize: '30px' }} />
            </ListItemIcon>
            <ListItemText primary='CASH OUT' className={classes.listText} />
          </ListItem> */}

          <ListItem button onClick={() => GoToBetsTable()}>
            <ListItemIcon>
              <MonetizationOnRoundedIcon sx={{ color: 'white', fontSize: '30px' }} />
            </ListItemIcon>
            <ListItemText primary={t('stakinghistory')} className={classes.listText} />
          </ListItem>

          <ListItem button onClick={() => GoToCockPickHistory()}>
            <ListItemIcon>
              <HistoryRoundedIcon sx={{ color: 'white', fontSize: '30px' }} />
            </ListItemIcon>
            <ListItemText primary={t('last2dhistory')} className={classes.listText} />
          </ListItem>

          <ListItem button onClick={() => GoToAccounts()}>
            <ListItemIcon>
              <SwitchAccountIcon sx={{ color: 'white', fontSize: '30px' }} />
            </ListItemIcon>
            <ListItemText primary={t('profile')} className={classes.listText} />
          </ListItem>
          {/* <ListItem button onClick={() => GoToReferal()}>
            <ListItemIcon>
              <TbUsersPlus style={{ color: 'white', fontSize: '30px' }} />
            </ListItemIcon>
            <ListItemText primary={t('myreferrals')} className={classes.listText} />
          </ListItem> */}

          <ListItem button onClick={() => GoToHelp()}>
            <ListItemIcon>
              <HelpOutlineIcon sx={{ color: 'white', fontSize: '30px' }} />
            </ListItemIcon>
            <ListItemText primary={t('help')} className={classes.listText} />
          </ListItem>

          <ListItem button onClick={() => logOut()}>
            <ListItemIcon>
              <ExitToAppIcon sx={{ color: 'white', fontSize: '30px' }} />
            </ListItemIcon>
            <ListItemText primary={t('signout')} className={classes.listText} />
          </ListItem>

          {/* <ListItem button>
            <Box
              component='img'
              sx={{
                height: '30px',
                width: '30px',
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt='The house from the offer.'
              src={Messageicon}
            />
            <ListItemText primary='MESSAGE' className={classes.listText} />
          </ListItem> */}

          {/* <ListItem button>
            <Box
              component='img'
              sx={{
                height: '30px',
                width: '30px',
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt='The house from the offer.'
              src={Networkicon}
            />
            <ListItemText primary='GLOBE' className={classes.listText} />
          </ListItem> */}

          {/* <ListItem button sx={{ mt: 3 }}>
            <Box
              component='img'
              sx={{
                height: '30px',
                width: '30px',
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt='The house from the offer.'
              src={Settingsicon}
            />
            <ListItemText primary='SETTINGS' className={classes.listText} />
          </ListItem> */}
        </List>
      </Box>
    </div>
  );
};

export default DrawerContent;
